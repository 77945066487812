import React from "react";

const Footer = () => {
  return (
    <footer className="credits" fixed="bottom">
      <p className="text-muted">
        Created by Mark Cross | Powered by React&nbsp;
        <i className="fab fa-react"></i> | Copyright © 2022 &nbsp;Photo by&nbsp;
        <a href="https://unsplash.com/@kovacsz1?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
          Z S
        </a>
        &nbsp;on&nbsp;
        <a href="https://unsplash.com/?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
          Unsplash
        </a>
      </p>
    </footer>
  );
};
export default Footer;
